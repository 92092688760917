import Layout from '@/components/Layout';
import HeroImageLg from '@/public/assets/hero-lg.png';
import HeroImageSm from '@/public/assets/hero-lg.png';
import SwitchToProductionMode from '@/components/SwitchToProdMode';
import classes from './styles.module.scss';
import {
  Box,
  Image,
  Heading,
  Flex,
  Text,
  useMediaQuery,
  toast,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import CTA from 'components/UI/CTA';
import ToggleRow from 'components/ToggleRow';
import SKCard from 'components/UI/SKCard';
import { SIKKA_FAQS } from '@/data/faq';
import { useState } from 'react';
import { useRouter } from 'next/router';

export default function HomePage() {
  const [lang, setLang] = useState('en'); //en||hi
  const router = useRouter();
  return (
    <Layout>
      <Flex
        flexDir={['column', 'column', 'row']}
        justifyContent={'center'}
        alignItems={'center'}
        pr={[2, 0]}
      // mt={-20}
      // mb={-24}
      >
        <Box pr={6} w={['100%']} display={['block', null, 'none']}>
          <Image
            src={HeroImageSm.src}
            alt="Rupiyo illustration"
            mt={[4, null, 'unset']}
            height={['45vh', '45vh', null, '50vh']}
            mx={'auto'}
          />
        </Box>

        <Flex
          flexDir={'column'}
          maxW={['100%']}
          pr={[0, 0, 0, 0, 8]}
          textAlign={['left']}
          mt={['20px', '50px']}
        >
          <Heading
            mb={['10px', '20px']}
            fontSize={['26px', '36px', '44px', '46px', '52px']}
          >
            The best Way to{' '}
            <Text fontSize={['26px', '36px', '44px', '46px', '52px']}>
              <span className={classes.textBg}>
                <span className={classes.textFg}>earn money instantly</span>
              </span>
            </Text>{' '}
            with India’s newest Earning App
          </Heading>
          <Text
            fontSize={['16px', '16px', '18px', '20px']}
            fontWeight={['400']}
            lineHeight={[6, null, 8]}
            mb={'30px'}
            opacity={0.6}
            color={'#9D88B2'}
          >
            Earning money is never easy, it takes hard work and passion but how
            about we make it easy for you? Presenting you the newest application
            where you can earn money instantly.
          </Text>
          <Flex justifyContent={['center', 'flex-start']} px={[4, 0]}>
            <CTA />
          </Flex>
        </Flex>

        <Box display={['none', null, 'block']}>
          <Image
            src={HeroImageLg.src}
            alt="Rupiyo illustration"
            width={['40vw']}
            maxWidth={['550px']}
          />
        </Box>
      </Flex>
      <Flex
        direction={'column'}
        gap={6}
        alignItems={['flex-start', null, 'center']}
        mt={['3rem']}
      >
        {lang === 'hi' ? (
          <Heading fontSize={'1.5rem'}>अक्सर पूछे जाने वाले प्रश्न</Heading>
        ) : (
          <Heading fontSize={'1.5rem'}>Frequently asked questions</Heading>
        )}
        <div style={{ margin: '1rem 0rem' }}>
          <ButtonGroup variant="solid" spacing="0" size="xs" isAttached>
            <Button
              colorScheme={lang === 'en' ? 'brand' : 'gray'}
              onClick={() => setLang('en')}
            >
              English
            </Button>
            <Button
              colorScheme={lang === 'hi' ? 'brand' : 'gray'}
              onClick={() => setLang('hi')}
            >
              Hindi
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.FaqList}>
          {SIKKA_FAQS.map(d => (
            <SKCard className={classes.card} id={`${d.key}`} key={d.key}>
              <ToggleRow
                key={d.key}
                header={
                  <div className={classes.rowHeader}>{d[lang].title}</div>
                }
                body={d[lang].desc}
                defaultOpen={`#${d.key}` === `#${router.asPath.split('#')[1]}`}
              />
            </SKCard>
          ))}
        </div>
      </Flex>
      <SwitchToProductionMode />
    </Layout>
  );
}
